<template>
  <page-view>
    <div class="admin_header_wrapper">
      <a-row :gutter="48">
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: 0, marginRight: '24px' }"
        >
          <div class="filter_item">
            <span>订单号</span>
            <a-input
              placeholder="订单号"
              v-model.trim="queryParam.no"
              class="filter_input"
            />
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: 0, marginRight: '24px' }"
        >
          <div class="filter_item">
            <span>贴牌用户ID</span>
            <a-input
              placeholder="贴牌用户ID"
              v-model="queryParam.oemUid"
              class="filter_input"
            />
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: 0, marginRight: '24px' }"
        >
          <div class="filter_item">
            <span>手机号</span>
            <a-input
              placeholder="手机号"
              v-model="queryParam.mobile"
              class="filter_input"
            />
          </div>
        </a-col>

        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>用户ID</span>
            <a-input
              placeholder="用户ID"
              v-model="queryParam.uid"
              class="filter_input"
            />
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="48">
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: 0, marginRight: '24px' }"
        >
          <div class="filter_item">
            <span>备注</span>
            <a-input
              placeholder="备注信息"
              v-model="queryParam.comment"
              class="filter_input"
            />
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: 0, marginRight: '24px' }"
        >
          <div class="filter_item">
            <span>代发端</span>
            <a-select
              placeholder="请选择"
              :allowClear="true"
              v-model.trim="queryParam.sellUid"
              class="filter_input"
            >
              <a-select-option
                v-for="array in addressListData"
                :key="array.value"
                >{{ array.label }}</a-select-option
              >
            </a-select>
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: 0, marginRight: '24px' }"
        >
          <div class="filter_item">
            <span>平台</span>
            <a-select
              placeholder="请选择"
              :allowClear="true"
              v-model.trim="queryParam.platformType"
              class="filter_input"
            >
              <a-select-option
                v-for="array in platformTypeArray"
                :key="array.value"
                >{{ array.name }}</a-select-option
              >
            </a-select>
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>查询时间</span>
            <a-range-picker
              class="filter_input"
              :value="[
                moment(queryParam.payTimeStart),
                moment(queryParam.payTimeEnd)
              ]"
              @change="onChangeDatePay"
            />
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="48">
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: 0, marginRight: '24px' }"
        >
          <div class="filter_item">
            <span>客服ID</span>
            <a-input
              placeholder="客服ID"
              v-model="queryParam.customerUid"
              class="filter_input"
            />
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ marginLeft: '24px', padding: 0 }"
        >
          <div class="time_select">
            <span
              v-for="item in shortcutDate"
              :key="item.id"
              @click="handleShortcutDate(item)"
              :class="currentShortcutDate === item.date ? 'active' : ''"
              >{{ item.title }}</span
            >
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ marginLeft: '24px', padding: 0 }"
        >
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="$refs.table.refresh(false)"
              >查询</a-button
            >
            <a-button @click="resetQueryParam">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="admin_chart_wrapper">
      <a-tabs
        :activeKey="activeChartTabKey"
        @change="handleChartTabChange"
        style="width:1617px"
      >
        <a-tab-pane tab="实时概况" key="realTime"></a-tab-pane>
        <a-tab-pane tab="以往数据" key="previousData"></a-tab-pane>
        <span
          slot="tabBarExtraContent"
          v-if="activeChartTabKey == 'realTime'"
          class="tab_time"
          >对比时间:{{ compareTimeTab }}</span
        >
        <span slot="tabBarExtraContent" v-else class="tab_time"
          >更新时间:{{ updateTimeTab }}</span
        >
      </a-tabs>
      <div class="all_chart" v-if="activeChartTabKey == 'realTime'">
        <a-row :gutter="48" class="row">
          <a-col :md="5" :sm="24" :style="{ padding: 0 }">
            <div class="chart_item">
              <div class="chart_info">
                <div class="num_title">下单用户数</div>
                <div class="num_total">
                  {{ orderCountTotal.uid ? orderCountTotal.uid : 0 }}
                </div>
              </div>
              <smoothLine
                :id="'chartWrapper1'"
                :lineStyle="{ width: '320px', height: '135px' }"
                :optionSeries="optionSeries1"
                :xAxisOption="xAxisOption"
                :tooltips="tooltips1"
                :grid="grid1"
              ></smoothLine>
            </div>
          </a-col>
          <a-col :md="5" :sm="24" :style="{ padding: 0 }">
            <div class="chart_item">
              <div class="chart_info">
                <div class="num_title">子订单数</div>
                <div class="num_total">
                  {{ orderCountTotal.count ? orderCountTotal.count : 0 }}
                </div>
              </div>
              <smoothLine
                :id="'chartWrapper2'"
                :lineStyle="{ width: '320px', height: '135px' }"
                :optionSeries="optionSeries2"
                :xAxisOption="xAxisOption"
                :tooltips="tooltips1"
                :grid="grid1"
              ></smoothLine>
            </div>
          </a-col>
          <a-col :md="13" :sm="24" :style="{ padding: 0 }">
            <div class="chart_rig_num">
              <div class="chart_info">
                <div class="num_title">用户总付款</div>
                <div class="num_total">
                  {{ orderCountTotal.amount ? orderCountTotal.amount : 0 }}
                </div>
              </div>
              <div class="chart_info">
                <div class="num_title">贴牌收入</div>
                <div class="num_total">
                  {{
                    orderCountTotal.incomeOem ? orderCountTotal.incomeOem : 0
                  }}
                </div>
              </div>
              <div class="chart_info">
                <div class="num_title">推广贴牌</div>
                <div class="num_total">
                  {{
                    orderCountTotal.incomeOemP ? orderCountTotal.incomeOemP : 0
                  }}
                </div>
              </div>
              <div class="chart_info">
                <div class="num_title">推广收入</div>
                <div class="num_total">
                  {{
                    orderCountTotal.incomeInviter
                      ? orderCountTotal.incomeInviter
                      : 0
                  }}
                </div>
              </div>
              <div class="chart_info">
                <div class="num_title">代发收入</div>
                <div class="num_total">
                  {{
                    orderCountTotal.incomeActual
                      ? orderCountTotal.incomeActual
                      : 0
                  }}
                </div>
              </div>
              <div class="chart_info">
                <div class="num_title">官方总利润</div>
                <div class="num_total">
                  {{
                    orderCountTotal.incomeAdmin
                      ? orderCountTotal.incomeAdmin
                      : 0
                  }}
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="all_chart2" v-else>
        <div class="all_left">
          <div class="top_left">
            <div class="all_chart2_item">
              <div class="num_title2">下单用户数</div>
              <div class="num_total2">
                {{ orderCountTotal.uid ? orderCountTotal.uid : 0 }}
              </div>
            </div>
            <div class="all_chart2_item">
              <div class="num_title2">子订单数</div>
              <div class="num_total2">
                {{ orderCountTotal.count ? orderCountTotal.count : 0 }}
              </div>
            </div>
          </div>
          <div class="all_chart2_line">
            <smoothLine
              v-if="currentShortcutDate == 1"
              :id="'chartWrapper3'"
              :lineStyle="{ width: '100%', height: '210px' }"
              :optionSeries="optionSeries3"
              :xAxisOption="xAxisOption2"
              :tooltips="tooltips1"
              :legend="legend"
              :grid="grid2"
            ></smoothLine>
            <smoothLine
              v-else
              :id="'chartWrapper3'"
              :lineStyle="{ width: '100%', height: '210px' }"
              :optionSeries="optionSeries3"
              :xAxisOption="xAxisOption2"
              :tooltips="tooltip2"
              :legend="legend"
              :grid="grid2"
            ></smoothLine>
          </div>
        </div>

        <div class="all_right">
          <div class="right_item">
            <div class="num_title2">用户总付款</div>
            <div class="num_total2">
              {{ orderCountTotal.amount ? orderCountTotal.amount : 0 }}
            </div>
          </div>
          <div class="right_item">
            <div class="num_title2">贴牌收入</div>
            <div class="num_total2">
              {{ orderCountTotal.incomeOem ? orderCountTotal.incomeOem : 0 }}
            </div>
          </div>
          <div class="right_item">
            <div class="num_title2">推广贴牌</div>
            <div class="num_total2">
              {{ orderCountTotal.incomeOemP ? orderCountTotal.incomeOemP : 0 }}
            </div>
          </div>
          <div class="right_item">
            <div class="num_title2">推广收入</div>
            <div class="num_total2">
              {{
                orderCountTotal.incomeInviter
                  ? orderCountTotal.incomeInviter
                  : 0
              }}
            </div>
          </div>
          <div class="right_item">
            <div class="num_title2">代发收入</div>
            <div class="num_total2">
              {{
                orderCountTotal.incomeActual ? orderCountTotal.incomeActual : 0
              }}
            </div>
          </div>
          <div class="right_item">
            <div class="num_title2">官方总利润</div>
            <div class="num_total2">
              {{
                orderCountTotal.incomeAdmin ? orderCountTotal.incomeAdmin : 0
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="admin_table_wrapper">
      <a-tabs :activeKey="activeTabKey" @change="handleTabChange">
        <a-tab-pane
          :tab="array.name"
          v-for="array in orderStatusArray"
          :key="array.value"
        ></a-tab-pane>
      </a-tabs>
      <div class="admin_table_box">
        <s-table
          style="margin-top: 10px"
          ref="table"
          rowKey="id"
          :columns="columns"
          :data="dataSource"
        >
          <a-table
            rowKey="id"
            slot="expandedRowRender"
            slot-scope="record"
            :columns="innerColumns"
            :dataSource="record.details"
            :pagination="false"
          >
            <span slot="serial" slot-scope="text, record, index">{{
              index + 1
            }}</span>

            <template slot="address" slot-scope="text, detail">
              {{
                detail.province + detail.city + detail.county + detail.address
              }}
            </template>
            <template
              v-for="col in ['waybillCompany', 'waybillNo']"
              :slot="col"
              slot-scope="text, detail, index"
            >
              <div :key="col">
                <a-input
                  v-if="detail.editable"
                  style="margin: -5px 0"
                  :value="text"
                  @change="e => handleChange(e.target.value, detail, col)"
                />
                <template v-else>{{ text }}</template>
              </div>
            </template>
          </a-table>
          <span slot="serial" slot-scope="text, record, index">{{
            index + 1
          }}</span>
          <!-- 订单号 -->
          <template slot="no" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>
              <span
                class="uid_fat"
                @click="copyInfo(record.no)"
                @mouseover="handleMouseover(record, 'show1')"
                @mouseout="handleMouseout(record, 'show1')"
              >
                {{
                  text.substring(0, 2) +
                    "……" +
                    text.substring(text.split("").length - 2)
                }}
                <span class="uid_child" v-show="record.show1">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>
          <!-- uid -->
          <template slot="uid" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>
              <span
                class="uid_fat"
                @click="copyInfo(record.uid)"
                @mouseover="handleMouseover(record, 'show2')"
                @mouseout="handleMouseout(record, 'show2')"
              >
                {{
                  text.substring(0, 2) +
                    "……" +
                    text.substring(text.split("").length - 2)
                }}
                <span class="uid_child" v-show="record.show2">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>
          <!-- 推广id -->
          <template slot="inviterUid" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>
              <span
                class="uid_fat"
                @click="copyInfo(record.inviterUid)"
                @mouseover="handleMouseover(record, 'show3')"
                @mouseout="handleMouseout(record, 'show3')"
              >
                {{
                  text.substring(0, 2) +
                    "……" +
                    text.substring(text.split("").length - 2)
                }}
                <span class="uid_child" v-show="record.show3">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>
          <!-- 分站id -->
          <template slot="oemUid" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>
              <span
                class="uid_fat"
                @click="copyInfo(record.oemUid)"
                @mouseover="handleMouseover(record, 'show4')"
                @mouseout="handleMouseout(record, 'show4')"
              >
                {{
                  text.substring(0, 2) +
                    "……" +
                    text.substring(text.split("").length - 2)
                }}
                <span class="uid_child" v-show="record.show4">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>
          <!-- 备注 -->
          <template slot="comment" slot-scope="text, record">
            <a-tooltip
              placement="right"
              overlayClassName="tooltip_custom"
              v-if="text"
            >
              <template slot="title">
                <span>点击复制</span>
              </template>

              <span style="cursor: pointer" @click="copyInfo(record.comment)">{{
                text.trim().substring(0, 1) +
                  "……" +
                  text.charAt(text.length - 1)
              }}</span>
            </a-tooltip>

            <span v-else>-</span>
          </template>
          <template slot="picture" slot-scope="text, record">
            <img
              :src="text"
              @click="() => (preview = { show: true, image: text })"
              style="width: 45px; height: 45px"
            />
            {{ record.goods.name }}
          </template>
          <template slot="status" slot-scope="text">
            <div
              class="circle"
              :class="
                text == 'UNPAID'
                  ? 'color1'
                  : '' || text == 'IN'
                  ? 'color2'
                  : '' || text == 'SUCCESS'
                  ? 'color3'
                  : '' || text == 'CANCEL'
                  ? 'color4'
                  : '' || text == 'ABNORMAL'
                  ? 'color5'
                  : ''
              "
            ></div>
            <span>{{ orderStatus[text] }}</span>
          </template>
          <template slot="operation" slot-scope="text, record">
            <a @click="getUserByUid(record.uid)">查看联系方式</a>
          </template>
        </s-table>
      </div>
    </div>
    <a-modal
      :visible="preview.show"
      :footer="null"
      @cancel="() => (preview.show = false)"
    >
      <img style="width: 100%" :src="preview.image" />
    </a-modal>
    <a-modal
      style="width: 200px"
      :visible="user.show"
      :footer="null"
      @cancel="() => (user.show = false)"
    >
      <p>用户ID：{{ user.detail.id }}</p>
      <p>用户昵称：{{ user.detail.username }}</p>
      <p>用户手机号：{{ user.detail.mobile }}</p>
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from "@/components";
import { PageView } from "@/layouts";
import { orderList, sipUserByUid, orderCount, addressList } from "@api";
import {
  formatDate,
  orderStatus,
  jsonToArray,
  platformType,
  timestampToTime
} from "@/utils/util";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import SmoothLine from "@/components/SmoothLine";
export default {
  data() {
    return {
      addressListData: [],
      platformTypeArray: jsonToArray(platformType),
      activeTabKey: "all",
      activeChartTabKey: "realTime",
      user: { detail: {}, show: false },
      preview: { image: "", show: false },
      upload: false,
      originalData: "", // 编辑前的原始数据
      orderStatus: orderStatus,
      orderStatusArray: jsonToArray(orderStatus),
      payModal: false,
      currentShortcutDate: 0,
      orderCountTotal: {},
      queryParam: {
        payTimeStart: new Date(new Date().toLocaleDateString()).getTime(),
        payTimeEnd:
          new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000
      },
      columns: [
        { title: "序号", scopedSlots: { customRender: "serial" }, width: 60 },
        {
          title: "订单号",
          dataIndex: "no",
          scopedSlots: { customRender: "no" }
        },
        {
          title: "商品信息",
          dataIndex: "goods.picture",
          scopedSlots: { customRender: "picture" },
          width: 165
        },
        { title: "子订单数", dataIndex: "count", width: 90 },
        { title: "网站利润", dataIndex: "incomeOem" },
        { title: "网站推广利润", dataIndex: "incomeOemP" },
        { title: "推广利润", dataIndex: "incomeInviter" },
        { title: "代发收入", dataIndex: "incomeActual" },
        { title: "官方收入", dataIndex: "incomeAdmin" },
        {
          title: "订单状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" }
        },
        {
          title: "推广人ID",
          dataIndex: "inviterUid",
          scopedSlots: { customRender: "inviterUid" }
        },
        {
          title: "用户ID",
          dataIndex: "uid",

          scopedSlots: { customRender: "uid" }
        },
        {
          title: "贴牌ID",
          dataIndex: "oemUid",
          scopedSlots: { customRender: "oemUid" }
        },
        {
          title: "备注",
          dataIndex: "comment",
          width: 85,
          scopedSlots: { customRender: "comment" }
        },
        {
          title: "平台",
          dataIndex: "platformType",
          customRender: text => platformType[text]
        },
        {
          title: "付款时间",
          dataIndex: "payTime",
          customRender: formatDate,
          width: 110
        },
        {
          title: "操作",
          dataIndex: "createTime",
          scopedSlots: { customRender: "operation" },
          width: 80
        }
      ],

      innerColumns: [
        { title: "#", scopedSlots: { customRender: "serial" } },
        { title: "订单id", dataIndex: "id" },
        { title: "订单号", dataIndex: "orderNo" },
        { title: "姓名", dataIndex: "name" },
        { title: "手机号", dataIndex: "mobile" },
        {
          title: "地址详情",
          dataIndex: "address",
          scopedSlots: { customRender: "address" }
        },
        {
          title: "快递公司",
          dataIndex: "waybillCompany",
          scopedSlots: { customRender: "waybillCompany" }
        },
        {
          title: "快递编号",
          dataIndex: "waybillNo",
          scopedSlots: { customRender: "waybillNo" }
        },
        {
          title: "发货时间",
          dataIndex: "deliveryTime",
          customRender: formatDate
        },
        {
          title: "操作",
          dataIndex: "createTime",
          scopedSlots: { customRender: "operation" }
        }
      ],

      dataSource: parameter => {
        return orderList(Object.assign(parameter, this.queryParam), 9).then(
          result => {
            this.originalData = ""; // 设置原始数据为空
            //总额
            orderCount(this.queryParam, 9).then(res => {
              this.orderCountTotal = res.data;
            });
            //range hour/day
            let range = "";
            if (
              this.currentShortcutDate == 0 ||
              this.currentShortcutDate == 1
            ) {
              range = "hour";
            } else {
              range = "day";
            }
            // 获取具体数据
            orderCount(
              Object.assign(parameter, this.queryParam, { range: range }),
              9
            ).then(orderCountResult => {
              this.formatLineData(orderCountResult.data);
            });
            result.data.list.forEach(item => {
              item.show1 = false;
              item.show2 = false;
              item.show3 = false;
              item.show4 = false;
            });
            return result.data;
          }
        );
      },
      shortcutDate: [
        {
          id: 1,
          title: "今天",
          date: 0
        },
        {
          id: 2,
          title: "昨天",
          date: 1
        },
        {
          id: 3,
          title: "最近7天",
          date: 7
        },
        {
          id: 4,
          title: "最近30天",
          date: 30
        }
      ],
      // 今天/昨天 工具提示
      tooltips1: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          lineStyle: {
            color: "rgba(0,0,0,0.15)",
            width: 1,
            type: "solid"
          }
        },
        formatter(params, ticket, callback) {
          let htmlStr = "";
          for (var i = 0; i < params.length; i++) {
            const param = params[i];
            const xName = param.name; //x轴的名称
            const seriesName = param.seriesName; //图例名称
            const value = param.value; //y轴值
            const color = param.color; //图例颜色

            if (i == 0) {
              if (xName < 10) {
                xName = "0" + xName;
              }
              htmlStr += `<span style="color:rgba(255,255,255,0.45)">${xName}:00~${xName}:59 <br/></span>`;
              htmlStr += "<div>";
              htmlStr += `<div style="width:4px;height:12px;background-color:${color};display:inline-block;margin-right:4px"></div>`;
              htmlStr += `<span style="color:rgba(255,255,255,0.65)">${seriesName}：<span style="color:rgba(255,255,255,1)">${value}</span></span>`;
              htmlStr += "</div>";
            } else {
              htmlStr += "<div>";
              htmlStr += `<div style="width:4px;height:12px;background-color:${color};display:inline-block;margin-right:4px"></div>`;
              htmlStr += `<span style="color:rgba(255,255,255,0.65)">${seriesName}：<span style="color:rgba(255,255,255,1)">${value}</span></span>`;

              htmlStr += "</div>";
            }
          }
          return htmlStr;
        }
      },
      tooltip2: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          lineStyle: {
            // 直线指示器样式设置
            color: "rgba(0,0,0,0.15)",
            width: 1,
            type: "solid"
          }
        },
        formatter(params, ticket, callback) {
          let htmlStr = "";
          for (var i = 0; i < params.length; i++) {
            const param = params[i];
            const xName = param.name; //x轴的名称
            const seriesName = param.seriesName; //图例名称
            const value = param.value; //y轴值
            const color = param.color; //图例颜色
            const day = new Date(Date.parse(xName.replace(/-/g, "/")));
            const today = new Array(
              "星期日",
              "星期一",
              "星期二",
              "星期三",
              "星期四",
              "星期五",
              "星期六"
            ); //创建星期数组
            const weekD = today[day.getDay()]; //周几
            if (i == 0) {
              htmlStr += `<span style="color:rgba(255,255,255,0.45)">${xName} ${weekD}<br/></span>`;
              htmlStr += "<div>";
              htmlStr += `<div style="width:4px;height:12px;background-color:${color};display:inline-block;margin-right:4px"></div>`;
              htmlStr += `<span style="color:rgba(255,255,255,0.65)">${seriesName}：<span style="color:rgba(255,255,255,1)">${value}</span></span>`;
              htmlStr += "</div>";
            } else {
              htmlStr += "<div>";
              htmlStr += `<div style="width:4px;height:12px;background-color:${color};display:inline-block;margin-right:4px"></div>`;
              htmlStr += `<span style="color:rgba(255,255,255,0.65)">${seriesName}：<span style="color:rgba(255,255,255,1)">${value}</span></span>`;
              htmlStr += "</div>";
            }
          }
          return htmlStr;
        }
      },
      grid1: {
        top: "5px",
        left: "40px",
        right: "0",
        bottom: "18px",
        containLabel: true
      },
      grid2: {
        top: "80px",
        left: "24px",
        right: "24px",
        bottom: "24px",
        containLabel: true
      },
      legend: {
        top: "2%",
        right: "2%",
        textStyle: {
          color: "rgba(0,0,0,0.65)",
          fontSize: 12,
          fontFamily: "PingFang SC"
        },
        itemWidth: 12,
        itemHeight: 12,
        data: [
          {
            name: "下单用户数",
            icon: "rect"
          },
          {
            name: "子订单数",
            icon: "rect"
          }
        ],
        orient: "vertical"
      },
      xAxisOption: {
        xAxisData: [],
        xAxisInterval: 11
      },
      xAxisOption2: {
        xAxisData: []
      },
      optionSeries1: [
        {
          symbol: "none",
          name: "今天",
          data: [],
          type: "line",
          smooth: true,
          itemStyle: {
            color: "#5B8FF9"
          }
        },

        {
          symbol: "none",
          name: "昨天",
          data: [],
          type: "line",
          smooth: true,
          itemStyle: {
            color: "#CDDDFD"
          }
        }
      ],
      optionSeries2: [
        {
          symbol: "none",
          name: "今天",
          data: [],
          type: "line",
          smooth: true,
          itemStyle: {
            color: "#33C6F7"
          }
        },

        {
          symbol: "none",
          name: "昨天",
          data: [],
          type: "line",
          smooth: true,
          itemStyle: {
            color: "#C1EEFD"
          }
        }
      ],

      optionSeries3: [
        {
          symbol: "none",
          name: "下单用户数",
          data: [],
          type: "line",
          smooth: true,
          itemStyle: {
            color: "#0D6EFF"
          }
        },

        {
          symbol: "none",
          name: "子订单数",
          data: [],
          type: "line",
          smooth: true,
          itemStyle: {
            color: "#33C6F7"
          }
        }
      ],
      optionSeries4: [
        {
          symbol: "none",
          name: "下单用户数",
          data: [],
          type: "line",
          smooth: true,
          itemStyle: {
            color: "#0D6EFF"
          }
        },

        {
          symbol: "none",
          name: "子订单数",
          data: [],
          type: "line",
          smooth: true,
          itemStyle: {
            color: "#33C6F7"
          }
        }
      ]
    };
  },
  created() {
    let obj2 = {
      name: "全部订单",
      value: "all"
    };
    this.orderStatusArray.unshift(obj2);
    addressList("").then(res => {
      this.addressListData = res.data
        .filter(item => {
          return item.name;
        })
        .map(item => {
          return {
            value: item.uid,
            label: item.name
          };
        });
    });
  },
  components: {
    PageView,
    STable,
    SmoothLine
  },
  methods: {
    moment,
    formatLineData(data) {
      if (this.currentShortcutDate == 0) {
        let arr = this.hoursRange(data);
        this.optionSeries1[0].data = arr.uid;
        this.optionSeries2[0].data = arr.count;
        // 如果是今天还要请求一次昨天的数据
        let today = new Date(new Date().toLocaleDateString()).getTime();
        let params = {
          payTimeStart: today - 24 * 60 * 60 * 1000,
          payTimeEnd: today,
          range: "hour",
          pageNum: 1,
          pageSize: 20
        };
        orderCount(params, 9).then(res2 => {
          let arr = this.hoursRange(res2.data);
          this.optionSeries1[1].data = arr.uid;
          this.optionSeries2[1].data = arr.count;
          this.xAxisOption.xAxisData = arr.arr;
        });
      } else if (this.currentShortcutDate == 1) {
        let arr = this.hoursRange(data);
        this.optionSeries4 = JSON.parse(JSON.stringify(this.optionSeries3));
        if (this.optionSeries3[0]) {
          this.optionSeries3[0].data = arr.uid;
        }
        if (this.optionSeries3[1]) {
          this.optionSeries3[1].data = arr.count;
        }
        this.xAxisOption2.xAxisData = arr.arr;
      } else {
        // 7  30 天
        let arr = this.dayRange(data);
        this.optionSeries4 = JSON.parse(JSON.stringify(this.optionSeries3));
        if (this.optionSeries3[0]) {
          this.optionSeries3[0].data = arr.uid;
        }
        if (this.optionSeries3[1]) {
          this.optionSeries3[1].data = arr.count;
        }
        this.xAxisOption2.xAxisData = arr.dateList;
      }
    },
    hoursRange(data) {
      let newData = data.map(item => {
        return {
          uid: Number(item.uid),
          count: item.count,
          time: Number(item.t.split(" ")[1].replace(/^0/, " "))
        };
      });
      // 时间点数组  数据数组
      let arr = Array.from(new Array(24).keys());
      let uid = new Array(24).fill(0);
      let count = new Array(24).fill(0);
      newData.forEach(item => {
        // 数据对象某一项的时间等于时间点数组的某一项
        // 拿到这个时间的索引
        // 作为当前索引 赋值数据
        arr.forEach(item2 => {
          uid.forEach(item3 => {
            if (item.time == item2) {
              uid[item2] = item.uid;
            }
          });
          count.forEach(item3 => {
            if (item.time == item2) {
              count[item2] = item.count;
            }
          });
        });
      });
      return { arr, uid, count };
    },
    // 近n天
    timestampToTime,
    dayRange(data) {
      var dateTime = this.queryParam.payTimeEnd - 86400000;
      //时间数组
      var dateList = [];
      for (var i = this.currentShortcutDate - 1; i >= 0; i--) {
        dateList[i] = this.timestampToTime(dateTime);
        dateTime = dateTime - 86400000;
      }
      // --
      let newData = data.map(item => {
        return {
          uid: Number(item.uid),
          count: item.count,
          time: item.t
        };
      });
      // 一开始置0;
      let uid = new Array(this.currentShortcutDate).fill(0);
      let count = new Array(this.currentShortcutDate).fill(0);
      //数据对象
      newData.forEach((item, index) => {
        dateList.forEach((item2, index2) => {
          uid.forEach(item3 => {
            if (item.time == item2) {
              uid[index2] = item.uid;
            }
          });
          count.forEach(item3 => {
            if (item.time == item2) {
              count[index2] = item.count;
            }
          });
        });
      });
      return { dateList, uid, count };
    },
    handleTabChange(key) {
      this.activeTabKey = key;
      if (key == "all") {
        //全部时不传状态
        const { status, ...params } = this.queryParam;
        this.queryParam = params;
      } else {
        this.queryParam.status = key;
      }
      this.$refs.table.refresh(false);
    },
    handleChartTabChange(key) {
      this.activeChartTabKey = key;
      this.optionSeries3 = JSON.parse(JSON.stringify(this.optionSeries4));
      this.formatTabRightTime();
      let today = new Date(new Date().toLocaleDateString()).getTime();
      if (key == "previousData") {
        //昨天
        this.currentShortcutDate = 1;
        this.queryParam = {
          payTimeStart: today - 24 * 60 * 60 * 1000,
          payTimeEnd: today
        };
      } else {
        //今天
        this.currentShortcutDate = 0;
        this.queryParam = {
          payTimeStart: today,
          payTimeEnd: today + 24 * 60 * 60 * 1000
        };
      }
      this.$refs.table.refresh(false);
    },
    resetQueryParam() {
      // 重置就查今天的全部
      this.currentShortcutDate = 0;
      this.activeChartTabKey = "realTime";
      this.activeTabKey = "all";
      this.queryParam = {
        payTimeStart: new Date(new Date().toLocaleDateString()).getTime(),
        payTimeEnd:
          new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000
      };
      this.$refs.table.refresh(false);
    },

    handleMouseover(record, key) {
      record[key] = true;
    },
    handleMouseout(record, key) {
      record[key] = false;
    },
    getUserByUid(uid) {
      sipUserByUid(uid).then(res => {
        this.user.detail = res;
        this.user.show = true;
      });
    },
    edit(detail) {
      this.originalData = JSON.stringify(detail);
      detail.editable = true;
    },
    handleChange(value, detail, column) {
      detail[column] = value;
    },
    onChangeDatePay(date, dateString) {
      const time = dateString.includes("");
      if (time) {
        delete this.queryParam.payTimeStart;
        delete this.queryParam.payTimeEnd;
      } else {
        this.queryParam.payTimeStart =
          Date.parse(new Date(dateString[0])) - 1000 * 3600 * 8;
        this.queryParam.payTimeEnd =
          Date.parse(new Date(dateString[1])) - 1000 * 3600 * 8;
      }
      this.currentShortcutDate =
        (this.queryParam.payTimeEnd - this.queryParam.payTimeStart) / 86400000;
      this.currentShortcutDate == 0
        ? (this.activeChartTabKey = "realTime")
        : (this.activeChartTabKey = "previousData");
      this.formatTabRightTime();
      this.$refs.table.refresh(false);
    },
    handleShortcutDate(record) {
      this.currentShortcutDate = record.date;
      this.currentShortcutDate == 0
        ? (this.activeChartTabKey = "realTime")
        : (this.activeChartTabKey = "previousData");
      this.optionSeries3 = JSON.parse(JSON.stringify(this.optionSeries4));
      this.formatTabRightTime();
      // 今天
      let payTimeStart;
      let payTimeEnd;
      let today = new Date(new Date().toLocaleDateString()).getTime();
      if (record.date === 0) {
        payTimeStart = today;
        payTimeEnd = today + 24 * 60 * 60 * 1000;
      }
      if (record.date === 7 || record.date === 30) {
        payTimeEnd = today + 24 * 60 * 60 * 1000;
        payTimeStart = today - 24 * 60 * 60 * 1000 * (record.date - 1);
      }
      if (record.date === 1) {
        payTimeStart = today - 24 * 60 * 60 * 1000;
        payTimeEnd = today;
      }
      this.queryParam.payTimeStart = payTimeStart;
      this.queryParam.payTimeEnd = payTimeEnd;
      this.$refs.table.refresh(false);
    },
    formatTabRightTime() {
      let time = new Date().getTime();
      this.updateTimeTab = moment(time).format("YYYY-MM-DD HH:mm:ss");
    }
  },
  computed: {
    compareTimeTab() {
      const time1 = new Date(new Date().getTime());
      const time2 = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      const start = moment(time1).format("YYYY-MM-DD");
      const end = moment(time2).format("YYYY-MM-DD");
      return start + "~" + end;
    }
  }
};
</script>

<style lang="less" scoped>
.row {
  margin: 0 !important;
}
.admin_header_wrapper {
  padding: 24px 40px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    span {
      width: 70px;
      text-align: right;
    }
    .filter_input {
      width: 240px;
      margin-left: 6px;
    }
    .query_btn {
      margin-right: 12px;
    }
  }
  .time_select {
    span {
      display: inline-block;
      margin-right: 24px;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
    .active {
      color: #1890ff;
    }
  }
}
.admin_chart_wrapper {
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  margin-bottom: 24px;
  border-radius: 4px;
  overflow-x: scroll;
  .all_chart {
    width: 1637px;
    margin-top: 8px;

    .chart_info {
      text-align: left;
      padding-left: 64px;
      .num_title {
        margin-bottom: 8px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
      .num_total {
        font-size: 24px;
        font-family: Roboto;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .chart_rig_num {
      display: flex;
      flex-wrap: wrap;

      .chart_info {
        width: 295px;
        padding-left: 115px;
        margin-bottom: 40px;
        border-right: 1px solid #f0f0f0;
        &:nth-child(3n) {
          border: 0;
        }
        .num_title {
          color: rgba(0, 0, 0, 0.45);
        }
        .num_total {
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
  .all_chart2 {
    margin-top: 8px;
    display: flex;
    width: 1637px;

    .num_title2 {
      margin-bottom: 8px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .num_total2 {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
    }
    .all_left {
      // border: 1px solid skyblue;

      width: 46%;
      position: relative;
      .top_left {
        display: flex;
        position: absolute;
        top: 0;
        left: 64px;
        display: flex;
        .all_chart2_item {
          margin-right: 78px;
        }
      }
    }

    .all_right {
      margin-top: -24px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .right_item {
        width: 33%;
        padding-left: 115px;
        border-right: 1px solid #f0f0f0;
        .num_title2 {
          color: rgba(0, 0, 0, 0.45);
        }
        .num_total2 {
          color: rgba(0, 0, 0, 0.65);
        }
        &:nth-child(3n) {
          border-right: 0;
        }
      }
    }
  }
}
.admin_table_wrapper {
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  margin-bottom: 24px;
  border-radius: 4px;
  .admin_table_box {
    padding: 0 24px 24px;
  }
}
.circle {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
}
.color1 {
  background: rgba(0, 0, 0, 0.45);
}
.color2 {
  background: rgba(13, 110, 255, 1);
}
.color3 {
  background: rgba(82, 196, 26, 1);
}
.color4 {
  background: rgba(250, 173, 20, 1);
}
.color5 {
  background: rgba(255, 77, 79, 1);
}
.input,
.input2,
.input3,
.input4,
.input5 {
  opacity: 0;
  width: 10px;
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -50px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    z-index: 9999;
  }
}
.tab_time {
  line-height: 53px;
}
.top5 {
  margin-bottom: 8px;
}
//tab栏
/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 16px 0 16px;
  padding: 16px 12px;
}
/deep/ .ant-tabs {
  margin-right: 24px;
}
/deep/ .ant-tabs-extra-content {
  line-height: 53px;
}
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #eee;
}

::-webkit-scrollbar {
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(216, 213, 213);
}
</style>
